@keyframes swipe {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  10% {
    opacity: 0.9;
    transform: translateY(0);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  90% {
    opacity: 0.9;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.swipe-animation {
  display: inline-block;
  animation: swipe 1500ms infinite;
}
