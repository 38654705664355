.hero-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 80px 60px;
  background-color: #f8f8f8;
  height: 100vh;
}

.hero-column {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  justify-content: center;
}

.hero-column p {
  text-align: left;
}

.hero-column img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (min-width: 1200px) {
  .hero-column:first-child {
    padding-left: 140px;
  }
}
