.app-navbar {
  background-color: #282c34;
  padding: 12px;
  color: white;
  text-align: left;
}

.flex-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.space-between {
  justify-content: space-between;
}
